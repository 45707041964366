import React from "react";
import { Link } from "react-router-dom";
import User from "../assets/images/banner.png";
import action_config from './config/config'
import NoData from "./seperatemodals/nodata";
import config from './config/config'
export default function Following({datas,type,user}) {
//  console.log("sari sari",datas)
  return (
    <>
      <div className="inner-content follow">
        <h4 className="title-dashboard" data-aos="fade-right">

        {type == 'follower' ? 'Followers' : type == 'collection' ? 'Collection': 'Following'} 
        </h4>
    <div className="content-follow">
      {/* {console.log('collectionnn',datas,type)} */}
    { datas?.length>0 ?
      datas?.map(data=> 
          <>
          {console.log('datttaa',data)}
          {type !== "collection" || user }
          <div className="card-author" data-aos="zoom-in-down">
            <div className="avatar">
            {type == 'collection' && <img src={data?.CollectionProfileImage ? action_config.IMG_URL+"/collection/"+data?.CollectionSymbol+'/'+data?.CollectionProfileImage  : require("../assets/images/banner.png")} alt="images" />}
            {(type == 'follower'||type == 'following') && <img src={data?.Profile ? action_config.IMG_URL+"/user/"+data?.WalletAddress+'/profile/'+data?.Profile  : config.DefaultImg} alt="images" />}
            </div>
            <div className="name">
              {" "}
              <Link to={type == 'collection'
              ?`/collection/${data?.CollectionSymbol}`
              :`/my-item/${data?.CustomUrl}`
              } state={{data}}>{(data?.DisplayName)}</Link>{" "}

            </div>
            {/* <div className="details">
              <span>{data.items}</span> Items
            </div> */}
            <Link to={type == 'collection'
              ?`/collection/${data?.CollectionSymbol}`
              :`/my-item/${data?.CustomUrl}`
              } state={{data:data,types : type == 'follower' ? 'Follower': 'Following'}} className="btn-follow">
             {type == 'follower' ? 'Follower' :type == 'collection' ? 'Collection': 'Following'} 
            </Link>
            {/* <Link to="#" className="option">
              <i className="far fa-ellipsis-h"></i>
            </Link> */}
          </div></>)
        :
        <NoData/>
        } 
        </div>
      </div>
    </>
  );
}
