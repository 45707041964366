import { useMemo, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import useContractProviderHook from "./../../actions/contractProviderHook";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty } from "../../actions/common";
// import useAxiosFile from "../../actions/useaxios";
import config from "../config/config";
import { useEffect } from "react";
import { BuyAccept } from "../../actions/axioss/nft.axios";
import { error } from "jquery";
export function BuyNow({ OpenPopup, closePop, owner, item }) {
  console.log("buyyyyyyyy", item, owner);
  const { currency } = useSelector((state) => state.LoginReducer);
  const { web3, web3p, accountAddress, coinBalance } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  // console.log('coinnnnballl',coinBalance,web3p.utils.fromWei(coinBalance.toString()))
  const { buyerFees, sellerFees } = useSelector(
    (state) => state.LoginReducer.ServiceFees
  );
  //console.log('getServiceFees',buyerFees,sellerFees)
  const ContractCall = useContractProviderHook();
  const push = useNavigate();
  // const AxiosFile = useAxiosFile()
  const { payload } = useSelector((state) => state.LoginReducer.User);

  const [Btn, SetBtn] = useState("start");
  const [App_Btn, SetApp_Btn] = useState(
    owner.CoinName != "BNB" ? "init" : "start"
  );
  const [Error, SetError] = useState("");
  const [NFTQuantity, SetNFTQuantity] = useState("1");
  const [TokenBalance, SetTokenBalance] = useState("0");
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => closePop();
  const [proceedtopayment, setProceedtopayment] = useState(false);
  const decimal =
    currency
      ?.filter(
        (item) =>
          item.label ===
          (owner.CoinName == "PancakeSwap Token" ? "CAKE" : owner.CoinName)
      )
      ?.pop()?.decimal ?? 18;
  const token_address =
    currency
      ?.filter(
        (item) =>
          item.label ===
          (owner.CoinName == "PancakeSwap Token" ? "CAKE" : owner.CoinName)
      )
      ?.pop()?.address ?? config.DEADADDRESS;
  console.log("aaaaaaabbbb", owner, item);

  const YouWillGet = useMemo(() => {
    return ContractCall.buy_bid_price_calculation(
      (owner.NFTPrice * Number(NFTQuantity)).toString(),
      decimal.toString()
    );
  }, [owner.TokenPrice, NFTQuantity]);
  
  console.log("YouWillGet",YouWillGet);
  const Validation = async () => {
   console.log("efrawefrawrw",NFTQuantity);
    const re = /[0-9]/;
    var error = {};
    if (Number(owner.NFTBalance) > 1 && isEmpty(NFTQuantity))
      return "Token Quantity Required";
   //  else if (owner.NFTBalance > 1 && NFTQuantity.match(re))
   //    return "Token Quantity must be in number";
    else if (Number(owner.NFTBalance) > 1 && (Number(owner.NFTBalance) < Number(NFTQuantity)))
      return (error.NFTQuantity =
        "NFT Quantity should be less than " + owner.NFTBalance);
    if (
      owner.CoinName != "BNB" &&
      Number(owner.TokenPrice) * Number(NFTQuantity) > Number(TokenBalance)
    )
      return "Insufficient Balance";
    else return await ContractCall.Contract_Base_Validation();
  };
  console.log(
    "tokenaddress in buy now",
    token_address,
    currency?.filter((item) => item.label === owner.CoinName),
    currency,
    owner.CoinName
  );
  const FormSubmit = async () => {
    const id = toast.loading("Purchasing Token on processing");
    SetError("");
    SetBtn("process");
    var error = await Validation();
    if (error) {
      toast.update(id, {
        render: error,
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      SetBtn("error");
      SetError(error);
    } else {
      let cont = await ContractCall.approve_721_1155(
        token_address,
        config.TradeContract,
        web3p.utils.toWei(YouWillGet.toString())
      );
      if (cont) {
        toast.update(id, {
          render: "Purchasing Token Successfully",
          type: "success",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetBtn("done");
        SetApp_Btn("start");
      } else {
        toast.update(id, {
          render: "Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetBtn("try");
      }
    }
  };

  useEffect(() => {
    (async () => {
      const TokenBalance = await ContractCall.Token_Balance_Calculation(
        token_address,
        accountAddress
      );
      // console.log('tokkkeeeenballl',TokenBalance)
      SetTokenBalance(TokenBalance ? TokenBalance : 0);
    })();
  }, [TokenBalance]);

  const _Buy = async () => {
    SetApp_Btn("process");
    // console.log('ghgdhdg',NFTQugetServiceFeesantity)
    const id = toast.loading("Purchasing Token on processing");
    var error = await Validation();
    console.log(
      "errrrrrrrrr",
      web3p.utils.toWei(YouWillGet.toString()),
      owner.CoinName == "PancakeSwap Token" ? "CAKE" : owner.CoinName,
      owner.NFTOwner,
      [
        owner.NFTId,
        web3p.utils.toWei(String(owner.NFTPrice * Number(NFTQuantity))),
        NFTQuantity,
        item.ContractType,
      ],
      item.ContractAddress
    );
    SetError(error);
    if (isEmpty(error)) {
      let cont = await ContractCall.buy_721_1155(
        web3p.utils.toWei(YouWillGet.toString()),
        owner.CoinName == "PancakeSwap Token" ? "CAKE" : owner.CoinName,
        owner.NFTOwner,
        [
          owner.NFTId,
          web3p.utils.toWei(String(owner.NFTPrice * NFTQuantity)),
          NFTQuantity,
          item.ContractType,
        ],
        item.ContractAddress
      );
      if (cont) {
        let newOwner = {
          HashValue: cont.HashValue,
          NewTokenOwner: accountAddress,
          NFTQuantity: NFTQuantity,
          NFTId: owner.NFTId,
          NFTOwner: owner.NFTOwner,
          PutOnSale: owner.PutOnSale,
          PutOnSaleType: owner.PutOnSaleType,
          activity: "Buy",
          TP: owner.NFTPrice,
          CN: owner.CoinName,
          click: `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${accountAddress}/${owner.NFTId}`,
        };
        let Resp = await BuyAccept({ newOwner: newOwner, item: item });
        if (Resp.success == "success") {
          toast.update(id, {
            render: "Approving Token Successfully",
            type: "success",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          SetApp_Btn("done");
          push(`/profile/${payload?.CustomUrl}`, { state: { Tab: "owned" } });
        } else {
          toast.update(id, {
            render: "Transaction Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          SetApp_Btn("try");
        }
      } else {
        toast.update(id, {
          render: "Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetApp_Btn("try");
      }
    } else {
      toast.update(id, {
        render: "Validation failed",
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
    }
  };

  const onChange = (e) => {
    var numberRegex = /^\d+$/;
    console.log("vallll", e.target.value);
    if (numberRegex.test(e.target.value) || e.target.value == "") {
      SetNFTQuantity(e.target.value);
      SetError("");
      SetBtn("start");
    } else {
      SetError("Token Quantity must be in number");
    }
  };

  useEffect(() => {
    async function BalanceCheck() {
      var Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);
      console.log(
        "ownneerrr ittemmm",
        owner,
        item,
        Nftbalance,
        owner.NFTBalance
      );
      if (Nftbalance !== owner.NFTBalance) {
        toast.warning("You won't buy at this moment please refresh you data");
        setTimeout(() => {
          push("/");
        }, 1000);
      }
    }
    BalanceCheck();
  }, [item, owner]);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      className={proceedtopayment ? "fixedmodel" : ""}
    >
      {/* {console.log('fgfdgfdhgfhb',NFTQuantity)} */}
      <Modal.Header>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={closePop}
        ></button>
        <Modal.Title id="contained-modal-title-vcenter">Checkout</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="buynowsec">
          <div className="buynowseller d-flex justify-content-between">
            <p>
              <b>Seller:</b>
            </p>
            <p>{owner.DisplayName ? owner.DisplayName : owner.NFTOwner}</p>
          </div>
          <div className="buynowseller d-flex justify-content-between">
            <p>
              <b>Buyer:</b>
            </p>
            <p>
              {payload?.DisplayName
                ? payload?.DisplayName
                : payload.WalletAddress}
            </p>
          </div>
        </div>
        <div className="buynowbalance mt-5">
          <div className="buynowseller  d-flex justify-content-between">
            <p>
              <b>Your Balance:</b>
            </p>
            <p>
              {owner.CoinName != "BNB"
                ? TokenBalance
                : web3p.utils.fromWei(coinBalance.toString())}{" "}
              {owner.CoinName}
            </p>
          </div>
          {/* <div className='buynowseller d-flex justify-content-between'>
               <p><b>Your Token Balance:</b></p><p>99999.8 T CAKE</p></div> */}
          <div className="buynowseller  d-flex justify-content-between">
            <p>
              <b>Price:</b>
            </p>
            <p>
              {owner.NFTPrice} {owner.CoinName}
            </p>
          </div>
          <div className="buynowseller  d-flex justify-content-between">
            <p>
              <b>Service Fee:</b>
            </p>
            <p>
              {web3p.utils.fromWei(String(buyerFees))}% {owner.CoinName}
            </p>
          </div>{" "}
          {/*{web3p.utils.fromWei(String(buyerFees))}*/}
          <div className="buynowseller d-flex justify-content-between">
            <p>
              <b>Royalty Fee:</b>
            </p>
            <p>
              {item.NFTRoyalty}% {owner.CoinName}
            </p>
          </div>
          <div className="buynowseller d-flex justify-content-between">
            <p>
              <b>You Will Pay:</b>
            </p>
            <p>
              {YouWillGet} {owner.CoinName}
            </p>
          </div>
          {owner.NFTBalance > 1 && (
            <div>
              <p className="text-left pb-2">
                Enter Quantity <span>({owner.NFTBalance} available)</span>
              </p>
              <input
                type="text"
                id="NFTQuantity"
                value={NFTQuantity}
                onChange={(e) => onChange(e)}
                name="NumOnly"
                className=""
                disabled={
                  owner.NFTBalance == "1" || owner.NFTBalance == 1
                    ? true
                    : false
                }
                placeholder="Enter your bid quantity"
              />
            </div>
          )}
          {Error && <span className="text-danger img-file">{Error}</span>}
        </div>
        <div className="connecwalletbtns d-flex justify-content-between mt-5">
          {owner.CoinName == "BNB" ? null : (
            <Button
              className="connectwallet primary"
              tabIndex="-1"
              disabled={
                Btn == "error" || Btn === "process" || Btn === "done"
                  ? true
                  : false
              }
              onClick={Btn == "start" || Btn === "try" ? FormSubmit : null}
            >
              {(Btn == "start" && "Approve") ||
                (Btn == "try" && "Try-Again") ||
                (Btn == "error" && "Error") ||
                (Btn == "done" && "Done") ||
                (Btn == "process" && "In-Progress")}
            </Button>
          )}
          <Button
            className={
              Btn === "done"
                ? "cancelpayment secondary"
                : "connectwallet hides primary"
            }
            tabIndex="-1"
            disabled={
              (Btn != "done" && App_Btn == "init") ||
              App_Btn == "error" ||
              App_Btn === "process" ||
              App_Btn === "done"
                ? true
                : false
            }
            onClick={App_Btn == "start" || App_Btn === "try" ? _Buy : null}
          >
            {(App_Btn == "start" && "Proceed to payment") ||
              (App_Btn == "try" && "Try-Again") ||
              (App_Btn == "error" && "Error") ||
              (App_Btn == "done" && "Done") ||
              (App_Btn == "process" && "In-Progress") ||
              (App_Btn == "init" && "Proceed to payment")}
          </Button>
          {/* <button className={proceedtopayment ? "connectwallet primary" : "connectwallet hides primary"} tabIndex="-1" aria-disabled="true" onClick={() => setProceedtopayment(true)}>Proceed</button>
            <button className='cancelpayment secondary' onClick={handleClose10}>Cancel</button> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}
