import config from '../../views/config/config'
import {axiosFunc, AppenData} from '../common'

var token;

  export const GetUserCookieToken = () => {
    token = document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        console.log('egfwafwer',parts)
        return parts[0] === "token" ? decodeURIComponent(parts[1]) : r
      }, '');
}

//user ProfileCreate, Update axios Function 
export const userRegister    =   async   (data)  =>  {
    // console.log("UserRegister is Calling",data)
    var formdata = AppenData(data)
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/user/create`,
        data    :   formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
          }
    }
    
    let Resp    =   await axiosFunc(senddata)
    // console.log('resppp',Resp,senddata,data)
    return Resp.data
}

export const Token_MyList_Func    =   async   (data)  =>  {
    var senddata    =   {
        method  :   'GET',
        url     :   `${config.BACK_URL}/nft/myItemList`,
        params  :   data
        }
    let Resp    =   await axiosFunc(senddata)

    return Resp.data
}

export const FollowUnFollowFunc = async (data) =>{
    var senddata ={
        method  :   'POST',
        url     :   `${config.BACK_URL}/user/FollowUnFollowFunc`,
        data    :    data,
        'headers': {
            'Authorization': token
          }
    }
    let Resp = await axiosFunc(senddata);
    
    return Resp.data;
}

export const GetLikeDataAction = async (data) => {
    var senddata = {
        method : 'POST',
        url    : `${config.BACK_URL}/nft/like/list`,
        data   :   data
    }
    let Resp   = await axiosFunc(senddata)
    return Resp.data
}

export const AddLikeAction = async (data) => {
    var senddata = {
        method : 'POST',
        url    : `${config.BACK_URL}/nft/like`,
        data   :   data,
        'headers': {
            'Authorization': token
          }
    }
    let Resp   = await axiosFunc(senddata)
    return Resp.data
}

export const findOwners= async (data)=>{
    var senddata = {
        method : 'GET',
        url    : `${config.BACK_URL}/nft/findOwners`,
        params:data
       
    }
    let Resp   = await axiosFunc(senddata)
    return Resp.data
}

export const Newsletter = async (data) =>{
    var senddata ={
        method  :   'POST',
        url     :   `${config.BACK_URL}/user/newsletter`,
        data    :    data
    }
    console.log('hdghgh',senddata)
    let Resp = await axiosFunc(senddata);
    
    return Resp.data;
}

export const report = async (data) =>{
    var senddata ={
        'method': 'post',
        'url':  `${config.BACK_URL}/nft/report`,
        data:data
    }
    let Resp = await axiosFunc(senddata);
    
    return Resp?.data;
}

export const getFaqList  = async()=>{
    
    try{
        var resp = await axiosFunc({
          
                'method':'GET',
                'url':`${config.ADMIN_URL }/getfaqlist`,
        })
        console.log("tokonwer resp",resp.data)
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
  }
  export const getFaqcontentList  = async()=>{
    
    try{
        var resp = await axiosFunc({
          
                'method':'GET',
                'url':`${config.ADMIN_URL }/getfaqcontentslist`,
        })
        console.log("tokonwer resp",resp.data)
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
  }

  export const SearchAction    =   async   (data)  =>  {
    var senddata    =   {
        method  :   'GET',
        url     :   `${config.BACK_URL}/nft/SearchAction`,
        params  :   data
        }
    let Resp    =   await axiosFunc(senddata)

    return Resp.data
}

export const Sociallinks = async() =>{
    
    var senddata ={
        method  :  'get',
        url     :  `${config.BACK_URL}/user/social`
    }
    let Resp = await axiosFunc(senddata);
    
    return Resp.data;
}
export const getcms= async ()=>{
   
    console.log("sdlksddas");
        let Resp   = await axiosFunc({
            method : 'GET',
            url    : `${config.BACK_URL}/user/getcms`,
           
        })
        console.log("concms list",Resp);
        return Resp.data
    }