import { Box, Button, Fade, Grid, Select } from "@mui/material";
import { Modal } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useContractProviderHook from "../../actions/contractProviderHook";
import ImgAudVideo from "../../separate/ImgAudVideo";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty, NumberOnly } from "../../actions/common";
import { BuyAccept } from "../../actions/axioss/nft.axios";

export function Transfer({
  OpenPopup,
  closePop,
  owner,
  file,
  type,
  thumb,
  noimg,
  item,
}) {
  const { web3, accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const { sellerFees } = useSelector((state) => state.LoginReducer.ServiceFees);
  const ContractCall = useContractProviderHook();
  const push = useNavigate();
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const [amount, setAmount] = useState("");
  const [Address, SetAddress] = useState("");
  const [Quantity, SetQuantity] = useState(owner.TokenQuantity);
  const [Btn, SetBtn] = useState("start");
  const [Error, SetError] = useState("");

  const Validation = async () => {
    if (isEmpty(Address)) return "User  Required";
    if ((Number(owner.NFTBalance) > 1) && isEmpty(amount)) return "Quantity  Required";
    if (amount > owner.NFTBalance)
      return "Token Quantity Must be less than " + owner.NFTBalance;
    if (Address === accountAddress)
      return "You Can't Transfer Token to Your Self";
    if (await ContractCall.Contract_Base_Validation())
      return await ContractCall.Contract_Base_Validation();
  };
  console.log("iiiiiiiiiiiiiitttttttttttt", item, owner,payload);
  const FormSubmit = async () => {
    const id = toast.loading("Transferring Your Price");
    SetError("");
    SetBtn("process");
    var error = await Validation();
    if (error) {
      toast.update(id, {
        render: error,
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      SetBtn("error");
      SetError(error);
    } else {
        var data = {
            nftOwner :  owner.NFTOwner,
            Address,
            NFTId : owner.NFTId,
            amount,
            datas:"0x0",
            ContractType : item.ContractType
        }
      let cont = await ContractCall.Transfer(data);

      if (cont) {


        // let newOwner = {
        //   HashValue: cont?.HashValue,
        //   NewTokenOwner: Address,
        //   Quantity: Quantity,
        //   TokenId: owner.NFTId,
        //   TokenOwner: owner.NFTOwner,
        //   TokenOwner_Name: payload.CustomUrl,
        //   OldTokenOwner_Name: owner.CustomUrl,
        //   PutOnSale: owner.PutOnSale,
        //   PutOnSaleType: owner.PutOnSaleType,
        //   activity: "Transfer",
        //   // New_EmailId : bidder.EmailId,
        //   // Old_EmailId : payload.EmailId,
        //   TP: 0,
        //   CN: "",
        // };

        let newOwner = {
            HashValue: cont.HashValue,
            NewTokenOwner: Address.toLowerCase(),
            NFTQuantity: amount == "" ? "1" : amount,
            NFTId: owner.NFTId,
            NFTOwner: owner.NFTOwner,
            PutOnSale: owner.PutOnSale,
            PutOnSaleType: owner.PutOnSaleType,
            activity: "Transfer",
            TP: 0,
            CN: ""
         }
        let Resp = await BuyAccept({ newOwner: newOwner, item: item });
        if (Resp.success == "success") {
          toast.update(id, {
            render: "Transferring Your Token Successfully",
            type: "success",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          SetBtn("done");
          push(`/profile/${payload?.CustomUrl}`,{state:{Tab:'owned'}});
        } else {
          toast.update(id, {
            render: "Transaction Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          SetBtn("try");
        }
      } else {
        toast.update(id, {
          render: "Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetBtn("try");
      }
    }
  };

  useEffect(() => {
    async function BalanceCheck() {
      var Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);
      console.log("ownneerrr ittemmm", owner, item, Nftbalance);
      if (Nftbalance !== owner.NFTBalance) {
        toast.warning("You won't buy at this moment please refresh you data");
        setTimeout(() => {
          push("/");
        }, 1000);
      }
    }
    BalanceCheck();
  }, [item, owner]);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      className={"fixedmodel"}
    >
      {/* {console.log('fgfdgfdhgfhb',NFTQuantity)} */}
      <Modal.Header>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={closePop}
        ></button>
        <Modal.Title id="contained-modal-title-vcenter">Transfer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="buynowsec">
          <div className="buynowseller d-flex justify-content-between">
            <p>
              <b>Nft Name:</b>
            </p>
            <p>{item.NFTName}</p>
          </div>
          {/* <div className='buynowseller d-flex justify-content-between'>
               <p><b>Buyer:</b></p><p>{payload?.DisplayName ? payload?.DisplayName : payload.WalletAddress}</p>
            </div> */}
        </div>
        <div className="buynowbalance mt-5">
          {/* <div className='buynowseller  d-flex justify-content-between'>
               <p><b>Your Balance:</b></p><p>{ owner.CoinName != "BNB" ? TokenBalance : web3p.utils.fromWei(coinBalance.toString()) } {owner.CoinName}</p></div>
            <div className='buynowseller d-flex justify-content-between'>
               <p><b>Your Token Balance:</b></p><p>99999.8 T CAKE</p></div>
            <div className='buynowseller  d-flex justify-content-between'>
               <p><b>Price:</b></p><p>{owner.NFTPrice} {owner.CoinName}</p></div>
            <div className='buynowseller  d-flex justify-content-between'>
               <p><b>Service Fee:</b></p><p>{web3p.utils.fromWei(String(buyerFees))}% {owner.CoinName}</p></div>
            <div className='buynowseller d-flex justify-content-between'>
               <p><b>Royalty Fee:</b></p><p>{(item.NFTRoyalty)}% {owner.CoinName}</p></div>
            <div className='buynowseller d-flex justify-content-between'>
               <p><b>You Will Pay:</b></p><p>{YouWillGet} {owner.CoinName}</p></div>
               <p className="text-left pb-2">Enter Quantity <span>({owner.NFTBalance} available)</span></p> */}
          <input
            type="text"
            id="ToAddress"
            //   value={NFTQuantity}
            onChange={(e) =>{ SetAddress(e.target.value);SetError("");SetBtn("start")}}
            // name="NumOnly"
            className=""
            // disabled={
            //   owner.NFTBalance == "1" || owner.NFTBalance == 1 ? true : false
            // }
            placeholder="Enter transfer to address"
          />
          {Error && <span className="text-danger img-file">{Error}</span>}

          {owner.NFTBalance > 1 && (
            <input
            style={{ marginTop:"3%" }}
              type="text"
              id="amount"
              //   value={NFTQuantity}
              onChange={(e) =>{ setAmount(e.target.value);SetError("");SetBtn("start")}}
              name="NumOnly"
              className=""
            //   disabled={
            //     owner.NFTBalance == "1" || owner.NFTBalance == 1 ? true : false
            //   }
              placeholder="Enter Quantity"
            />
          )}
        </div>
        <div className="connecwalletbtns d-flex justify-content-between mt-5">
          {(
            <Button
              className="connectwallet primary"
              tabIndex="-1"
              disabled={
                Btn == "error" || Btn === "process" || Btn === "done"
                  ? true
                  : false
              }
              onClick={Btn == "start" || Btn === "try" ? FormSubmit : null}
            >
              {(Btn == "start" && "Transfer") ||
                (Btn == "try" && "Try-Again") ||
                (Btn == "error" && "Error") ||
                (Btn == "done" && "Done") ||
                (Btn == "process" && "In-Progress")}
            </Button>
          )}

          <Button
            className="connectwallet primary"
            tabIndex="0"
            type="button"
            disabled={Btn === "process" || Btn === "done" ? true : false}
            onClick={closePop}
          >
            <span className="MuiButton-label">Cancel</span>
            <span className="MuiTouchRipple-root"></span>
          </Button>
          {/* <button className={proceedtopayment ? "connectwallet primary" : "connectwallet hides primary"} tabIndex="-1" aria-disabled="true" onClick={() => setProceedtopayment(true)}>Proceed</button>
            <button className='cancelpayment secondary' onClick={handleClose10}>Cancel</button> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}
