import React from "react";
import Wallet1 from "../assets/images/svg/icon-wallet-1.svg";
import Wallet2 from "../assets/images/svg/icon-wallet-2.svg";
import Wallet3 from "../assets/images/svg/icon-wallet-3.svg";
import Wallet4 from "../assets/images/svg/icon-wallet-4.svg";

import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { NavLink } from "react-router-dom";

import {Account_Section} from '../redux/constants'

import { connectWallet } from './hooks/useWallet'; 
//redux
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isEmpty } from '../actions/common';
import { useNavigate } from "react-router-dom";

import { userRegister,GetUserCookieToken} from '../actions/axioss/user.axios'
import { GetNftCookieToken } from "../actions/axioss/nft.axios";
import { useWeb3React } from '@web3-react/core';
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

// import { WalletConnectConnector } from "@web3-react/walletlink-connector";
// import { WalletLinkConnector,WalletConnectConnector } from "@web3-react/walletlink-connector";
import { Web3ReactProvider } from '@web3-react/core'
import Config from "./config/config"
import { Web3Provider } from "@ethersproject/providers";
import Web3 from "web3";

export default function ConnectWallet(){
    const [wallet,setWallet] = React.useState([
        {icon:Wallet1,beta:"Alpha",title:"Meta Mask",description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",id: "MetaMask"},
        {icon:Wallet3,beta:"Alpha",title:"Wallet Connect",description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",id: "WalletConnect"},
        // {icon:Wallet4,beta:"Alpha",title:"Coin Base",description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",id: "Coinbase"},
    ])
    const [mobileWallet,setMobileWallet] = React.useState([
        {icon:Wallet3,beta:"Alpha",title:"Wallet Connect",description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",id: "WalletConnect"},
        // {icon:Wallet4,beta:"Alpha",title:"Coin Base",description:"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",id: "CoinbaseMobile"},
    ])
    
    const dispatch = useDispatch();
    var navigate = useNavigate();
	const { token } = useSelector(state => state.LoginReducer.User)
    const { activate, deactivate, active, chainId, account } = useWeb3React();

    const initialConnectWallet = async (type)=>{
        
        const id=toast.loading(type+" Wallet Connecting...",{autoClose:1000,closeButton:true,closeOnClick:true})
        console.log('hfjdhfgjkde',type)
        if(type == 'CoinbaseMobile'){
            try{
            const provider = 
            // new WalletConnectConnector({

            //     rpcUrl: Config.RPC_URL,
               
            //     bridge: "https://bridge.walletconnect.org",
               
            //     qrcode: true,
               
            //    });
            new WalletLinkConnector({
                url: Config.RPC_URL,
                appName: "Web3-react Demo",
                supportedChainIds: [1, 3, 4, 5, 42],
               });
            activate(provider)
            var web3 = new Web3(provider);
            const accounts = await web3.eth.getAccounts();

            console.log('acccountsssss',active,chainId,account);
            }
            catch(err){
                console.log('connect error',err)
            }
        }
        else{
            var accountDetails = await connectWallet(type)
        // console.log("accountDetails",accountDetails)
        if(!isEmpty(accountDetails)){
            var resp = await setWalletAddress('InitialConnect',accountDetails.accountAddress,type)
            // console.log("resp",resp)
            if(resp?.success == 'success'){
                toast.update(id,{render : resp.msg , type:resp.success,autoClose:1000, isLoading: false,closeButton:true,closeOnClick:true})
                dispatch({
                    type: "Account_Section",
                    Account_Section: {AccountDetails:accountDetails}
                })
                navigate("/")
            }
            else{
                toast.update(id,{render : "Connect Wallet" , type:'error',autoClose:1000, isLoading: false,})
            }
                      
        }
        else toast.update(id,{render : "Try Again" , type:'error',autoClose:1000, isLoading: false,closeButton:true,closeOnClick:true})
        }
    }
    const setWalletAddress    =   async   (type,walletAddress,walletType)  =>  {
    if(walletAddress){
      var NewMethod = {
            Type: type,
            WalletAddress: walletAddress,
            WalletType: walletType,
      }; 
      let Resp = await userRegister(NewMethod);
    //   console.log('inittttt',Resp)
      if(Resp?.success == 'success'){
          dispatch({
              type:'Register_Section',
              Register_Section : {
                  User: {
                      payload	: 	Resp.data,
                      token     :   Resp.token ? Resp.token : token
                  }
              }
          })
          document.cookie = 'token' + "=" + Resp?.token + ";" + ";path=/";
          GetNftCookieToken();
          GetUserCookieToken();
          return Resp
      }
      else return Resp
  }  
  else return {success:'error',msg:'No Address Detected.. Check Your Wallet'}

  }

  function getLibrary(provider) {
    console.log("provider",provider);
    return new Web3Provider(provider);
  }

    
    return(
        <>
        <Header />
          <section className="tf-page-title" data-aos="fade-left">    
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="breadcrumbs">
                                <li><NavLink to="/">Home</NavLink></li>
                                <li>Connect Wallet</li>
                            </ul>
                        </div>
                    </div>
                </div>                    
            </section>
            {/* <Web3ReactProvider getLibrary={getLibrary}> */}
            <section className="tf-connect-wallet" >
                <div className="tf-container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="tf-heading style-5">
                                <h4 className="heading" data-aos="fade-up">Connect Your Wallet</h4>
                                <p className="sub-heading" data-aos="fade-down">Choose how you want to connect. There are several wallet providers.</p>
                            </div>
                        </div>
    
                        { window.ethereum && window.web3 ? wallet?.map((data,index) => {
                            return(
                        <div className="col-lg-4 col-md-6" data-aos="zoom-in">
                            <div className="tf-wallet" onClick={() => initialConnectWallet(data.id)}>
                                <div className="icon">
                                    <img src={data.icon} alt="Image" width="100" height="100" classNameName="img-fluid" />
                                    <span className="label">{data.beta}
                                    </span>
                                </div>
                                <h6 className="title">{data.title}</h6>
                                <p className="content">{data.description}</p>
                            </div>
                        </div>

                        )})
                    :
                    mobileWallet.map((data,index) => {
                        return(
                    <div className="col-lg-4 col-md-6" data-aos="zoom-in">
                        <div className="tf-wallet" onClick={() => initialConnectWallet(data.id)}>
                            <div className="icon">
                                <img src={data.icon} alt="Image" width="100" height="100" classNameName="img-fluid" />
                                <span className="label">{data.beta}
                                </span>
                            </div>
                            <h6 className="title">{data.title}</h6>
                            <p className="content">{data.description}</p>
                        </div>
                    </div>

                    )})
                    }
                       
                    </div>
                </div>
            </section>
            {/* </Web3ReactProvider> */}
        <Footer />    
        </>
    )
}