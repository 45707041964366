import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
// import { Link } from "react-router-dom";
import Activity from "./Activity";
import History from "./History";
import Following from "./Following";
import Favourites from "./Favourites";
import EditProfile from "./EditProfile";
import Myitems from "./my_item"
// import Inventory from "../assets/images/svg/activity.svg";
import Inventory from "../assets/images/activity.png";
import Inventory1 from "../assets/images/activityhover.png";

import Followers from "../assets/images/followers.png";
import Followers1 from "../assets/images/followershover.png";

import Followings from "../assets/images/following.png";
import Followings1 from "../assets/images/followinghover.png";

import Item from "../assets/images/myitem.png";
import Item1 from "../assets/images/myitemhover.png";

import account from "../assets/images/account.png";
import account1 from "../assets/images/accounthover.png";

import heart from "../assets/images/heart.png";
import heart1 from "../assets/images/hearthover.png";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import HistoryCon from "../assets/images/svg/history.svg";
import FollowingCon from "../assets/images/svg/following.svg";
import Favcon from "../assets/images/svg/fav.svg";
import Edit from "../assets/images/svg/edit.svg";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import action_config from './config/config';
import { address_showing,Name_showing } from "../actions/common";
import { FollowUnFollowFunc, Token_MyList_Func, userRegister } from "../actions/axioss/user.axios";
import { toast } from 'react-toastify'
import Button from "react-bootstrap/esm/Button";
import Card from "./Card";
import NoData from "./seperatemodals/nodata";
import config from "./config/config"
import ImportCollection from './seperatemodals/importcollection';

import users from '../assets/images/demoprofile.png'
import thumb from '../assets/images/demobanner.jpg'


export default function Board() {
    const location=useLocation();
    console.log("enna varuthu",location);

    const { payload } = useSelector(state => state.LoginReducer.User)
    const { accountAddress } = useSelector(state => state.LoginReducer.AccountDetails)
    const [filter, setFilter] = useState('activity');
    const [tab,SetTab] = useState('owned')
    const [Follow, SetFollow] = useState('follow');
    const { customurl } = useParams()
    const [userProfile, setUserProfile] = useState({})
    const [Tokens, SetTokens] = useState({ 'activity': { 'loader': true, page: 1, list: [] } })
    var LikeForwardRef = useRef();
    const [LikedTokenList, setLikedTokenList] = useState([]);
    var [loader, setLoader] = useState(true);
    var navigate = useNavigate();
    var [openpopup, setOpenpopup] = useState('');
    useEffect(() => {

        if(location?.state?.Tab){
            console.log("locatioj ",location,location.state,location.state.Tab);
            setFilter(location.state.Tab)
            // SetTabName(location.state.Tab)
          
        }
        if (typeof Tokens[filter] == 'undefined' && filter !== "importcollection") {
            Tokens[filter] = { page: 1, list: [], loader: false };
            SetTokens(Tokens);
            Explore(1, filter);
        }

    }, [filter])

    useEffect(() => {
       
        Explore();
        getProfileDetails()
        console.log("profile to the footer",location?.state);
        // if(location?.state){
        //     setFilter(`${location?.state}`)
        // }
        // if(accountAddress!==userProfile?.WalletAddress){
        // FollowUnFollow()
        // }
    }, [customurl, accountAddress, userProfile?.WalletAddress])

    //  useEffect(()=>{

    //     navigate('/')
    //  },[accountAddress])


    const getProfileDetails = async () => {
        var SendDATA = {
            CustomUrl: customurl,
            Type: 'getProfile',
            User: payload?.WalletAddress
        }
        var profileInfo = await userRegister(SendDATA)
        console.log('senndddd',profileInfo)
        if (profileInfo?.success == 'success' && profileInfo?.data?.WalletAddress) {
            setUserProfile(profileInfo.data)
            
            SetFollow(profileInfo.follow == "follow" ? "unfollow" : "follow")
        }
        else {
            const id = toast.loading("Searching User");
            toast.update(id, { render: 'User Not found', type: 'error', isLoading: false, autoClose: 700, closeButton: true, closeOnClick: true })
            setTimeout(function () {
                navigate('/')
            }, 2000);
        }
    }

    const FollowUnFollow = async () => {
        const id = toast.loading(Follow == "follow" ? "You are Following " + Name_showing(userProfile?.DisplayName) : "You are Un Following " + Name_showing(userProfile?.DisplayName))
        let SendData = {
            MyItemAddr: userProfile?.WalletAddress,
            ClickAddr: accountAddress,
            From: 'myitem',
            MyItemCustomUrl: userProfile?.CustomUrl,
            ClickCustomUrl: payload?.CustomUrl,
        }
        // console.log('senddataaa',SendData,userProfile,payload)
        let Resp = await FollowUnFollowFunc(SendData)
        if (Resp?.success) {
            SetFollow(Resp.msg == 'follow' ? 'unfollow' : 'follow')
            toast.update(id, { render: Resp.msg == 'follow' ? 'You are Following ' + Name_showing(userProfile?.DisplayName) + ' Successfully' : 'You are UnFollowing ' +Name_showing(userProfile?.DisplayName) + ' Successfully', type: 'success', isLoading: false, autoClose: 1000 })
        }
        else {
            toast.update(id, { render: 'Try Again', type: 'error', isLoading: false, autoClose: 1000, closeButton: true, closeOnClick: true })

        }
    }

    const Explore = async (data, tab) => {
        var page = data ? data : (Tokens[filter]?.page)
        var SendDATA = {
            TabName: tab ? tab : filter,
            limit: 12,
            CustomUrl: userProfile?.CustomUrl,
            NFTOwner: userProfile?.WalletAddress,
            page: page ?? 1,
            from: 'myItem',
            filter: 'LatestDrops',
        }
        let Resp = await Token_MyList_Func(SendDATA)
        setLoader((Resp?.data?.length == 0 || (SendDATA?.TabName == "usercollection" && Resp?.cursor == null)) ? false : true)
        if (Resp?.success == 'success' && Resp?.data?.length > 0) {
            SetTokens({
                ...Tokens, ...{
                    [filter]: {
                        list: [...Tokens[filter].list, ...Resp.data],
                        loader: (Resp.data.length == 0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
                        page: Tokens[filter].page
                    }
                }
            })
            if(location?.state){
                setFilter(`${location?.state}`)
            }
        }
    }

    const LoadMore = () => {
        Tokens[filter].page = Tokens[filter].page + 1;
        SetTokens(Tokens);
        Explore(Tokens[filter].page);

    }

    const CoverImg = async (event) => {
        const toastupd = toast.loading("You Updated Image")
        var reader = new FileReader()
        const { id, files } = event.target;
        var fileNameExt = files[0].name.substr(files[0].name.lastIndexOf(".") + 1);
        if (event.target.files && event.target.files[0]) {
            if (files, id, files[0].type.includes("image")) {
                var file = event.target.files[0];
                var Resp;
                if (id == 'coverupload') {
                    Resp = await userRegister({ Type: 'cover', WalletAddress: payload.WalletAddress, Cover: files[0] })
                    // console.log('coverrr',Resp)
                }
                else {
                    Resp = await userRegister({ Type: 'profileimage', WalletAddress: payload.WalletAddress, Profile: files[0] })
                    // console.log('coverrr',Resp)
                }
                if (Resp?.success == 'success') {
                    window.location.reload()
                    getProfileDetails();
                    toast.update(toastupd, { render: Resp.msg, type: 'success', isLoading: false, autoClose: 700, closeButton: true, closeOnClick: true })
                }
                // let Resp = await userRegister({Type:'cover',CustomUrl:payload.CustomUrl,Cover:files[0]})
            }
            else {
                toast.update(toastupd, { render: "Profile or Cover Image Must be a Image", type: 'error', isLoading: false, autoClose: 700, closeButton: true, closeOnClick: true })
            }
        }

        // let formData = { ...formValue, ...{ [id]: files[0] } };
    }

    function LikeList(data) {
        setLikedTokenList(data)
    }

    return (
        <>
            <Header />
            {/* {console.log('photooooo',Tokens,loader)} */}
            <div className="board">
                <section className="tf-page-title">
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="breadcrumbs" data-aos="fade-right">
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li>Profile</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="thumb-pagetitle">
                                {console.log('addresss',userProfile)}
                                <img src={userProfile?.Cover ? action_config.IMG_URL + "/user/" + userProfile?.WalletAddress + '/cover/' + userProfile?.Cover : config.CoverImg} />
                                {userProfile?.WalletAddress === payload?.WalletAddress &&
                                    <div className="edit_profile">
                                        <input type="file" id="coverupload" name="upload" onChange={(e) => CoverImg(e)} />
                                        <i className="fa fa-pencil"></i>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="tf-dashboard tf-tab">
                    <div className="tf-container">
                        <div className="row ">
                            <div className="col-xl-3 col-lg-12 col-md-12" data-aos="fade-up">
                                <div className="dashboard-user">
                                    <div className="dashboard-infor">
                                        <div className="avatar">
                                            {console.log("fkvjdfjh",userProfile?.Profile )}
                                            <img src={userProfile?.Profile ? action_config.IMG_URL + "/user/" + userProfile?.WalletAddress + '/profile/' + userProfile?.Profile : config.DefaultImg} alt="images" />
                                            {userProfile?.WalletAddress === payload?.WalletAddress &&
                                                <div className="edit_profile">
                                                    <input type="file" id="profileupload" name="upload" onChange={(e) => CoverImg(e)} />
                                                    <i className="fa fa-pencil" style={{ color: "#000" }}></i>
                                                </div>}
                                        </div>
                                        {/* <div className="name">{userProfile?.DisplayName}</div> */}
                                        {/* <div className="pax"><i className="fab fa-ethereum"></i>{address_showing(userProfile?.WalletAddress)}</div> */}
                                        <div className="description descname mt-4">
                                            {userProfile?.DisplayName}
                                        </div>

                                        {/* {console.log('fgfhgfhfhsfhs',userProfile)} */}
                                        {/* <ul className="social-item">
                                    {userProfile?.Facebook &&<li><a href={userProfile?.Facebook} target="_blank"><i className="fab fa-facebook-f"></i></a></li> }
                                    {userProfile?.Twitter &&<li><a href={userProfile?.Twitter} target="_blank"><i className="fab fa-twitter"></i></a></li> }
                                    {userProfile?.Instagram &&<li><a href={userProfile?.Instagram} target="_blank"><i className="fab fa-instagram"></i></a></li> }
                                    {userProfile?.Youtube &&<li><a href={userProfile?.Youtube} target="_blank"><i className="fab fa-youtube"></i></a></li> }
                                    </ul> */}
                                        {/* {console.log("userProfileee",userProfile?.WalletAddress,payload,userProfile)} */}
                                        {userProfile?.WalletAddress != payload?.WalletAddress &&
                                   <div className="text-center">
                                   <Button className="follow_btn" onClick={FollowUnFollow}>{Follow}</Button>
                               </div>}

                                    </div>
                                    <div className="dashboard-filter">
                                        <ul className="filter-menuu menu-tab">
                                            <li className={filter == "activity" ? "active dashboard" : "dashboard"} onClick={() => setFilter("activity")}><Link to="#">
                                                {/* <span className="img1"></span>  */}
                                                <img className="imagesside" src={Inventory} />
                                                <img className="imagesside1" src={Inventory1} />
                                                &emsp;Activity</Link></li>
                                            <li className={filter == 'follower' ? "active dashboard" : "dashboard"} onClick={() => setFilter('follower')}><Link to="#">
                                                {/* <img src={HistoryCon} /> */}
                                                <img className="imagesside" src={Followers} />
                                                <img className="imagesside1" src={Followers1} />
                                                &emsp;Followers</Link></li>
                                            <li className={filter == 'following' ? "active dashboard" : "dashboard"} onClick={() => setFilter('following')}><Link to="#">
                                                {/* <img src={FollowingCon} /> */}
                                                <img className="imagesside" src={Followings} />
                                                <img className="imagesside1" src={Followings1} />
                                                &emsp;Following </Link></li>
                                            {/* <li className={filter == 'collection' ? "active dashboard" : "dashboard"}onClick={() => setFilter('collection')}><Link to="#">
                                        <img src={require("../assets/images/collection.png")} />
                                        &emsp;Collection </Link></li> */}
                                            {/* {userProfile?.WalletAddress === payload?.WalletAddress && 
                                        <li className={filter == 'importcollection' ? "active dashboard" : "dashboard"}onClick={() => {setFilter('importcollection');setOpenpopup('collection')}}><Link to="#">
                                        <img src={require("../assets/images/collection.png")} />
                                        &emsp;Import Collection </Link></li>} */}
                                            <li className={filter == 'favourite' ? "active dashboard" : "dashboard"} onClick={() => setFilter('liked')}><Link to="#">
                                                {/* <img src={Favcon} />  */}
                                                <img className="imagesside" src={heart} />
                                                <img className="imagesside1" src={heart1} />
                                                &emsp;Favourites</Link></li>
                                            {userProfile?.WalletAddress === payload?.WalletAddress &&
                                                <li className={filter == 'account' ? "active dashboard" : "dashboard"} onClick={() => setFilter('account')}><Link to="#">
                                                    {/* <img src={Edit} /> */}
                                                    <img className="imagesside" src={account} />
                                                    <img className="imagesside1" src={account1} />
                                                    &emsp;Account Settings</Link></li>}
                                            <li className={filter == 'owned' ? "active dashboard" : "dashboard"} onClick={() => setFilter('owned')}>
                                                <Link to="#">
                                                    {/* <img src={require("../assets/images/MY-ITEMS-ICON.png")} /> */}
                                                    <img className="imagesside" src={Item} />
                                                    <img className="imagesside1" src={Item1} />
                                                    &emsp;My Items</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-12 col-md-12 overflow-table">
                                <div className="dashboard-content inventory content-tab" data-aos="fade-right">

                                    {filter == 'account'
                                        ? (<EditProfile
                                            GetProfile={getProfileDetails} />)
                                        : filter == "activity"
                                            ? <Activity
                                                datas={Tokens[filter]}
                                                Loadmore={LoadMore}
                                                List={Tokens[filter]}
                                                filter={filter}
                                                Explore={Explore}
                                                SetTokens={SetTokens}
                                                Tokens={Tokens}
                                                userProfile={userProfile}
                                                Loader={loader} />
                                            : (filter == 'following' || filter == 'follower' || filter == "collection")
                                                ? (<Following datas={Tokens[filter]?.list} type={filter} user={userProfile?.WalletAddress === payload?.WalletAddress} />)
                                                : filter == "liked" ?

                                                    <>
                                                        {/* <h3 className="profilename">Animakid</h3> */}
                                                        <h4 class="title-dashboard aos-init aos-animate" data-aos="fade-right">Favourites</h4>
                                                        <div className="row">
                                                            {/* {console.log('favsssss',Tokens[filter])} */}

                                                            {Tokens[filter]?.list.length > 0 ? Tokens[filter]?.list.map((val, index) =>
                                                                <div
                                                                    className="col-xl-4 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                                                                  
                                                                >                                           <Card
                                                                        product={val}
                                                                        index={index}
                                                                        LikeList={LikeList}
                                                                        LikedTokenList={LikedTokenList}
                                                                        setLikedTokenList={setLikedTokenList}
                                                                        LikeForwardRef={LikeForwardRef}
                                                                    />
                                                                </div>
                                                            )
                                                                :
                                                                <NoData />
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    filter == "owned" && <>
                                                        {/* <h3 className="profilename">Animakid</h3> */}
                                                       
                                                      <Myitems profile='true'/>

                                                        {/* <div className="row">
                                                         

                                                            {Tokens[filter]?.list.length > 0 ? Tokens[filter]?.list.map((val, index) =>
                                                                <div
                                                                    className="col-xl-4 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber"
                                                                    data-aos="zoom-in-up"
                                                                >
                                                                    <Card
                                                                        product={val}
                                                                        index={index}
                                                                        LikeList={LikeList}
                                                                        LikedTokenList={LikedTokenList}
                                                                        setLikedTokenList={setLikedTokenList}
                                                                        LikeForwardRef={LikeForwardRef}
                                                                    />
                                                                </div>
                                                            )
                                                                :
                                                                <NoData />
                                                            }
                                                        </div> */}
                                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
            {openpopup == 'collection' &&
                <ImportCollection
                    SetOpenPopup={setOpenpopup}
                    Creator={accountAddress}
                    Network={config.COIN_NAME}
                    SetFilter={setFilter}
                    // GetCollection = {()=>CollectionByCreate({Type: collection == 1 ? 721 : 1155,
                    //     Creator: accountAddress})}
                    // collection = {collection}
                    AccountAddress={accountAddress}
                />}
        </>
    )
}