import React, { useEffect, useState, useRef } from "react";
import Author from "../assets/images/product52.jpg";
import Card from "../views/Card.js";
import Tick from "../assets/images/svg/tick.svg";
import OwlCarousel from 'react-owl-carousel';
import rocket from "../assets/images/explore.png"
import loadmore from '../assets/images/loadmore.png'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink,Link,useLocation,useNavigate } from "react-router-dom";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import Countdown from 'react-countdown';
import Modals from "./Modals";
import { useSelector } from "react-redux";
import { CollectionByCreator, HotAuctionHotSales, Token_List_Func,homeSaledata, TopCreatorApi} from "../actions/axioss/nft.axios"
import {getCmsContent} from "../actions/axioss/cms.axios"
import { address_showing } from "../actions/common";
import config from './config/config'
import ImgAudVideo from "../separate/ImgAudVideo";
import NoData from "./seperatemodals/nodata";
import {SearchAction} from "../actions/axioss/user.axios"
import { Getpromotedtoken } from "../actions/axioss/nft.axios";
import { data } from "jquery";
import Bannercard from "./Bannercard";
import purchase from '../assets/images/purchase.png'
import creator from '../assets/images/create_sec.png'
import bannerdummy from '../assets/images/banner_image2.png'
// import { Web3Auth } from "@web3auth/modal";

export default function Home(){
    const[hotactionstate,SetHotAuctionstate]=useState(true)
    const[hotsalestate,SetHotsalestate]=useState(true)
    const [bannerstate,setBannerstatus]=useState(true)
    const [Topcreatorstate,setTopcreatorstate]=useState(true)
    const[createrstate,SetCreaterstate]=useState(true)
    const [show,setShow] = React.useState(false);
    const { payload,isAdmin } = useSelector(state => state.LoginReducer.User)
    const navigate = useNavigate()
    // const [ishome,setIshome] = React.useState(false);

    // const { state , pathname} = useLocation();
    // useEffect(()=>
    // {
    //     setIshome(state?true:false);
    //     if(ishome)
    //     {
    //         var ele = document.getElementById("liveauction");
    //         console.log(ele,"ele");
    //         if(ele)
    //         {
    //             if(ele != null && ele !="undefined")
    //             {
    //                 window.scrollTo(0,ele.offsetTop)
        
        
    //             }
    //         } 
    //         setIshome(false) 
    //     }
    //     else
    //     {
    //         alert(1)
    //         // state
    //         window.scrollTo(0,0)
    //     }

    //     // return () => state = null
      
    // },[state])

    // const pull_data = (data,click) => {
    //     console.log(data,click,"all data"); 
    //     LOGS DATA FROM CHILD (My name is Dean Winchester... &)
    //     var ele = document.getElementById(data);
    //     console.log(ele,"ele");
    //     if(click && data)
    //     {
    //         if(ele != null && ele !="undefined")
    //         {
    //             window.scrollTo(0,ele.offsetTop)
    
    
    //         }
    //     }
        
      

    //   }
  
const collection = ([
    {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
    {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
    {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
    {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
    {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
    {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
    {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
    {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
    {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"},
    {img:Author,title: "SweetGirlofCandy",createdName:"MariaBrownie@1123"}]
)
const slideone={
    dots: false,
    infinite: true,
    smartSpeed: 4000,
    autoplayHoverPause:true,
    autoplay:true,
    // loop:true,
    nav:false,
    margin:30,
    speed: 1500,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:2
        },
        1000:{
            items:3
        }
    },
   
}
const slidetwo ={
  dots: false,
  infinite: true,
  smartSpeed: 3500,
  autoplayHoverPause:true,
  autoplay:true,
  loop:true,
  nav:false,
  margin:30,
  speed: 1500,
  responsive:{
      0:{
          items:1
      },
      600:{
          items:2
      },
      1000:{
          items:3
      }
  },
}
var renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
    if (completed) {

      return <span>Waiting for Owner To Accept</span>
    } else {
      return <span> <span className="hourds">{formatTime(days)} d</span>{" "}
      <span className="semicolan">:</span>{" "} <span className="hourds">{formatTime(hours)} h</span> <span className="semicolan">:</span> <span className="hourds">{formatTime(minutes)} m</span> <span className="semicolan">:</span> <span className="hourds">{formatTime(seconds)} s</span> </span>;
    }
  };
  const formatTime = (time) => {
    return String(time).padStart(2, '0')
  }

    /** lp */
    const { Categorys } = useSelector((state) => state.LoginReducer);
    
  
    const [TopCreator , SetTopCreator ] = useState([])

    var LikeForwardRef = useRef();
    const [LikedTokenList, setLikedTokenList] = useState([]);


    const [homecontent,setHomecontent]=useState([]);

    const[homecontentmidd,setHomecontentmidd]=useState([])


    

    

    const [HotAuctionData, SetHotAuction] = useState({
        All: [], 
        "tab":"All"
      });
      const [BannerAuctionData, SetBannerAuction] = useState({
        All: [], 
        "tab":"All"
      });
      const [SaleData, SetSaleData] = useState({
        All: [],
      });
      const [category , setCategory ] = useState("All")
    const [CreateCollectionState, SetCreateCollectionState] = useState({
        All: [], 
        "tab":"All"
      });
    const [val, Setval] = useState("");
    const [Searchdata, SetSearch] = React.useState(null);
    const [filter, setFilter] = useState({auction:"Recent" , collection:"new" ,sale:"LatestDrops",old : "OLD"});
    var [filterview, setFilterview] = useState({auction:"Recently created" , collection:"Recently created" ,sale:"Recently created"})
    var [promotedToken,setPromotedtoken] = useState({});

    // const [homecontent,setHomecontent]=useState([]);
    // const[homecontentmidd,setHomecontentmidd]=useState([])
    // console.log("------------------",category);
    
    useEffect(()=>{
        Getandselldata();
        Getandsellnft();
        TopCreatorFunc()
        HotAuction(1,'All')
        BannerAuction(1,'All')
        HotSales('All')
        // Collectionlist('All')
        CollectionByCreate('All')
        GetPromtion();
    },[])

    const CollectionByCreate = async(data) =>  {
        var SendDATA = {
            tab: data,
            limit: 4,
            ProfileUrl:  "",
            page: 1,
            from: "home",
            filter: filter.collection,
          };
        let Resp = await CollectionByCreator(SendDATA)
        // console.log('fhgngfngf',Resp)
        SetCreateCollectionState({
            ...CreateCollectionState,
            ...{
                [data]: Resp?.data,
               "tab" : data,
               "filter" : filter.collection,
            },
          })
        setCategory(data)
    }
    const TopCreatorFunc    =   async() =>  {
        var resp    =   await TopCreatorApi()
        // console.log('resssspppppp',resp)
        SetTopCreator(resp?.data)
        setTopcreatorstate(false)
    }
    // console.log("HotAuctionData",HotAuctionData)
    const HotAuction    =   async(data, tabs) =>  {
         var SendDATA = {
          TabName: tabs,
          limit: 8,
          ProfileUrl:  "",
          page: 1,
          from: "Auction",
          filter: filter.auction,
        };
        let Resp = await Token_List_Func(SendDATA);
        console.log('====================================hot auction');
            console.log(Resp.data);
            console.log('====================================');
        if(Resp?.success =="success" ){

            
        SetHotAuction({
            ...HotAuctionData,
            ...{
                [tabs]: Resp?.data,
               "tab" : tabs,
               "filter" : filter.auction
            },
          })
          SetHotAuctionstate(false)
        }
        else{
            SetHotAuction({
                ...HotAuctionData,
                ...{
                    [tabs]: Resp?.data,
                   "tab" : tabs,
                   "filter" : filter.auction

            },
            })
            SetHotAuctionstate(false)
        }
        


    }
    const BannerAuction    =   async(data, tabs) =>  {
        var SendDATA = {
         TabName: tabs,
         limit: 1,
         ProfileUrl:  "",
         page: 1,
         from: "Auction",
         filter: filter.auction,
       };
       let Resp = await Token_List_Func(SendDATA);
       console.log("dcvjvj0",Resp?.data);
       if(Resp?.success =="success" ){
       SetBannerAuction({
           ...BannerAuctionData,
           ...{
               [tabs]: Resp?.data,
              "tab" : tabs,
              "filter" : filter.auction
           },
         })
       }
       else{
        SetBannerAuction({
               ...BannerAuctionData,
               ...{
                   [tabs]: Resp?.data,
                  "tab" : tabs,
                  "filter" : filter.auction

           },
           })
       }
       


   }
    const HotSales    =   async(tabs) =>  {
        var SendDATA = {
            TabName: tabs,
            limit: 8,
            ProfileUrl:  "",
            page: 1,
            from: "Sale",
            filter: filter.old
          };
          let Resp = await homeSaledata(SendDATA);
        //   console.log('resppppppp',Resp,'sgfvfs',tabs);
          if(Resp?.success =="success" ) {
            SetSaleData({
              ...SaleData,
              ...{
                  [tabs]: Resp?.data,
                 "tab" : tabs,
                 "filter" : filter.sale
              },
            })
            SetHotsalestate(false)
          }
          else{
            SetSaleData({
                ...SaleData,
                ...{
                    [tabs]: Resp?.data,
                   "tab" : tabs,
                   "filter" : filter.sale
                },
              })
              SetHotsalestate(false)
          }
    }


    function LikeList(data){
        setLikedTokenList(data)
    }

const Collectionlist=async(tabs)=>{
    var SendDATA = {
        TabName: tabs,
        limit: 4,
        ProfileUrl:  "",
        page: 1,
        from: "collection",
      };
      let Resp = await Token_List_Func(SendDATA);
      if(Resp?.success =="success" ) setCategory({
          ...SaleData,
          ...{
              [tabs]: Resp?.data,
             "tab" : tabs
          },
        })
}

const Getandselldata =async()=>{
   
     var resp = await getCmsContent("homepage_top");
    //  console.log("cmslist",resp.data)
     if(resp?.status)
     setHomecontent(resp.data);
 }
 const Getandsellnft =async()=>{
   
    var resp = await getCmsContent("homepage_middle");
    // console.log("cmslist",resp.data)
    if(resp?.status)
    setHomecontentmidd(resp.data);
}

const OnChange = async (value) => {
    // console.log("vallllllllllll",value);
    if (value) {
        Setval(value);
        var Resp = await SearchAction({
            keyword: value,
            limit: 3,
            page: 1,
            from: "home",
        });
        // console.log("response", Resp);
        if (Resp?.success === "success") {
            SetSearch(Resp);
        }
        else{
            SetSearch(null)
        }
    }
    else{
        SetSearch(null)
        Setval('')
    }
};
  
    const GetPromtion =async()=>{
        // var resp = await Getpromotedtoken()
        var protoken = await Getpromotedtoken();
        // console.log('DGJFGJFJD',protoken)
        if(protoken.success == 'success'){
            setPromotedtoken(protoken.data)
            setBannerstatus(false)
        }
    }

    useEffect(()=>{
        // if(filter?.auction)
        if(HotAuctionData.filter !== undefined && filter.auction !== HotAuctionData.filter){
            HotAuction('hi',HotAuctionData.tab)
        }
        if(BannerAuctionData.filter !== undefined && filter.auction !== BannerAuctionData.filter){
            HotAuction('hi',BannerAuctionData.tab)
        }
        if(SaleData.filter !== undefined && filter.sale !== SaleData.filter){
            HotSales(SaleData.tab)
        }
        if(CreateCollectionState.filter !== undefined && filter.collection !== CreateCollectionState.filter ){
            CollectionByCreate(CreateCollectionState.tab)
        }
    },[filter])

    const AuctionFilter = (val,show) =>{
        setFilter({...filter,...{auction:val}});
        setFilterview({...filterview,...{auction:show}});
    }

    const SaleFilter = (val,show) =>{
        setFilter({...filter,...{sale:val}});
        setFilterview({...filterview,...{sale:show}});
    }

    const CollectionFilter = (val,show) =>{
        setFilter({...filter,...{collection:val}});
        setFilterview({...filterview,...{collection:show}});
    }

    return(
        <>
        <div className="homepage" id="homescreen">
        <Header />
        <div className="modals"><Modals /></div>
               <section className="tf-section tf-top-banner-sec">
                <div className="tf-container">
                    <div className="row aligns_row">
                        <div className="col-md-6">
                        <h1 className="heading"><div className="heading" dangerouslySetInnerHTML={{__html:homecontent?.description}}></div></h1>
                        <p className="sub-heading"><div className="sub-heading" dangerouslySetInnerHTML={{__html:homecontent?.answer}}></div> </p>
              {/* <h1 className="heading">Buy, Sell NFTs & Get Rewards</h1>
                <p className="sub-heading">Buy, Sell NFTs & Get RewardsNFT Marketplace is the web3 NFT Marketplace where traders and collectors earn rewards.</p> */}
                <div className="btn-slider mb-5">
                                                        <NavLink to="/explore" className="tf-button style-2 explore_btn">Explore <span> <img src={rocket} />    </span></NavLink>
                                                    </div>
                        </div>
                        <div className="col-md-6 rightsec_banner">
                        {console.log("sdvsdvgsd0", promotedToken)}

                            {/* <img src={require('../assets/images/card_bg_banner.png')}/> */}
                            {/* {console.log("sdvsdvgsd0", BannerAuctionData[BannerAuctionData.tab])} */}
                            { bannerstate== true ?  <>
                                {/* <Bannercard  
                                                        product = {item} 
                                                        type="marketplace"
                                                        index={index}
                                                        LikeList={LikeList}
                                                        LikedTokenList={LikedTokenList}
                                                        setLikedTokenList={setLikedTokenList}
                                                        LikeForwardRef={LikeForwardRef}
                                                    /> */}
                                                    <img src={bannerdummy} className="img-fluid"/>
    {/* <div className="text-centre">
        
        <h3 className="load_text">Loading...</h3>
                
        </div> */}
    </>: promotedToken?.length>0 ? promotedToken?.map((item,index)=>
                       
                                                    <Bannercard  
                                                        product = {item} 
                                                        type="marketplace"
                                                        index={index}
                                                        LikeList={LikeList}
                                                        LikedTokenList={LikedTokenList}
                                                        setLikedTokenList={setLikedTokenList}
                                                        LikeForwardRef={LikeForwardRef}
                                                    />
                                                  
                                                    )
                        :
                        // <></>
                            <NoData/>
                                                }
                        </div>
                    </div>
                </div>
               </section>

            <section className="tf-section tf-top-seller">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tf-heading style-3 mb40 wow fadeInUp" data-aos="fade-up">
                                <h3 className="heading">Our Top Creators</h3>
                                <p className="sub-heading">Checkout Top Rated creators on the  SPECIEX NFT Marketplace </p>
                            </div>
                        </div>
                        {Topcreatorstate == true ? <>
    <div className="text-centre">
        <h3  className="load_text">Loading...</h3>
        {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
        {/* <p>Nothing for ReSale at this time</p> */}
                <div className="load-more">
                {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                </div>
        </div>
    </>: TopCreator?.length > 0 ?
                        TopCreator?.map((data,index) => {
                            return (
                        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
                            <div className="tf-author-wrap" data-aos="fade-up">
                                {/* <span className="number">{index+1}.</span> */}
                                <div className="tf-author">
                                    <div className="image">
                                    <NavLink    to={`/profile/${data.CustomUrl}`}> <img
                                        src={
                                        data?.Profile
                                            ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                            : require("../assets/images/creators_pf.png")
                                        }
                                        alt="Image" className="auttor"
                                    /></NavLink>
                                        {/* <img src={Tick} alt="Image" width="14" height="14" className="img-fluid tick"/> */}
                                            
                                    </div>
                                    <div className="content">
                                        <div className="title">
                                            <NavLink to={"/profile/"+data.CustomUrl} state={{Tab:'owned'}}> {data?.DisplayName
                              ? data?.DisplayName
                              : address_showing(
                                  data?.WalletAddress
                                )}</NavLink>
                                        </div>
                                        <div className="price">
                                            <span className="price-eth">$-{data.value}</span>
                                        </div>
                                    </div>
                                    <div className="item_count">
                                           <p className="counts">{data.tokenDetails ? data.tokenDetails : 0}</p>
                                           <p className="list">items</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                        )})
                        :
                        <NoData/>}
                    </div>
                </div>
            </section>

            <section id="hotauctionscroll" className="tf-section tf-hot-auction tf-filter">
                <div className="tf-container">
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="tf-heading style-3 mb23 wow fadeInUp" data-aos="fade-up">
                                <h3 className="heading">Hot Auction</h3>
                                {/* <p className="sub-heading">The most creative creator - Based on the last 30 days </p> */}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="top-menu"  data-aos="fade-up">
                                <ul className="filter-menu">
                              
                                <li className={HotAuctionData?.tab  == "All" ? "active" : ""} onClick={() => HotAuction(1,'All')}><a>All</a></li>

                                    {Categorys.map(item=>  <li className={HotAuctionData.tab == item.label ? "active" : ""} onClick={() => HotAuction(item.value,item.label)}><a>{item.label}</a></li>)}
                                    </ul>
                                <div id="item_category2" className="dropdown" >
                                    <Link to="#" className="btn-selector nolink ">{filterview.auction}</Link>
                                    <ul className="show">
                                        <li onClick={() => {AuctionFilter('Recent','Recently created')}}><span>Recently created</span></li>
                                        <li onClick={() => {AuctionFilter('BLTH','Bid Low to High')}}><span>Bid Low to High</span></li>
                                        <li onClick={() => {AuctionFilter('BHTL','Bid High to Low')}}><span>Bid High to Low</span></li>
                                        <li onClick={() => {AuctionFilter('OLD','Oldest')}}><span>Oldest</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tf-filter-container row"  data-aos="fade-up">
                       { hotactionstate== true ? <>
    <div className="text-centre">
        <h3>Loading ...</h3>
        {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
        {/* <p>Nothing for ReSale at this time</p> */}
                <div className="load-more">
                {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                </div>
        </div>
    </> : 
 SaleData[SaleData.tab]?.length>0 ? SaleData[SaleData.tab]?.slice(0,8).map((item,index)=>
 <div
 className="col-xl-3 col-lg-4 col-md-6  tf-loadmore 3d cyber"

>
 <Card  
                                         product = {item} 
                                         type="marketplace"
                                         index={index}
                                         LikeList={LikeList}
                                         LikedTokenList={LikedTokenList}
                                         setLikedTokenList={setLikedTokenList}
                                         LikeForwardRef={LikeForwardRef}
                                     />
                                     </div>
                                     )

    // HotAuctionData[HotAuctionData.tab]?.length>0 ? HotAuctionData[HotAuctionData.tab]?.map((item,index)=>
    //                    <div
    //                    className="col-xl-3 col-lg-4 col-md-6  tf-loadmore 3d cyber"
    //                    data-aos="zoom-in-up"
    //                  >
    //                                                 <Card  
    //                                                     product = {item} 
    //                                                     type="marketplace"
    //                                                     index={index}
    //                                                     LikeList={LikeList}
    //                                                     LikedTokenList={LikedTokenList}
    //                                                     setLikedTokenList={setLikedTokenList}
    //                                                     LikeForwardRef={LikeForwardRef}
    //                                                 />
    //                                                 </div>
    //                                                 )
                        :
                        // <></>
                            <NoData/>
                                                }
                       
                      
                    </div>
                    {HotAuctionData[HotAuctionData.tab]?.length>0 && <div className="col-md-12">
                        <div className="btn-loadmore wow fadeInUp" data-aos="fade-up">
                            <NavLink to="/explore" className="tf-button style-8 loadmore"><img src={loadmore} width={50} height={50}/></NavLink>
                        </div>
                    </div>}
                </div>
            </section>
            {/* <section className="tf-section  tf-filter">
                <div className="tf-container">
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="tf-heading style-3 mb28 wow fadeInUp"  data-aos="fade-up">
                                <h3 className="heading">Top Collection</h3>
                                <p className="sub-heading">The most well-known Collection - Based on the last 30 days  </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="top-menu wow fadeInUp" data-aos="fade-up">
                                <ul className="filter-menu">
                                 <li className={category == "All" ? "active" : ""} onClick={() => CollectionByCreate('All')}>All</li>

                                    {Categorys.map(item=>  <li className={category == item.label ? "active" : ""} onClick={() => CollectionByCreate(item.label)}>{item.label}</li>)}

                                    </ul>
                                  
                                <div id="item_category3" className="dropdown" style={show == true ? {zIndex:9999} : {zIndex:"auto"}}>
                                    <Link to="#" className="btn-selector nolink ">{filterview.collection}</Link>
                                    <ul className="show">
                                        <li onClick={()=>{CollectionFilter('new','Recently created')}}><span>Recently created</span></li>
                                        <li onClick={()=>{CollectionFilter('old','Oldest')}}><span>Oldest</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tf-filter-container row wow fadeInUp"  data-aos="fade-up">
                    
                    </div>
                    </div>
                    <section className="tf-section tf-top-collection tf-filter">
                        <div className="tf-container">
                    <div className="row">
                    {CreateCollectionState[CreateCollectionState.tab]?.length > 0 ?

                    <div className="col-md-12">
                            <div className="swiper-container collection-over">
                                <div className="swiper-wrapper">
                                <OwlCarousel className='owl-theme' {...slideone}>
                                {CreateCollectionState[CreateCollectionState.tab].map((item,index) => {
                                        return ( 
                                    <>
                                    {item?.CollectionCount >= 3 &&
                                    <div className="swiper-slide">                               
                                        <div className="slider-item">
                                            <div className="sc-product style1 collection collection2" >
                                                <div className="top">
                                                    <div className="content">
                                                        <Link to ={"/collection/"+item.CollectionSymbol}>
                                                        <div className="author-cl">
                                                            <img src={item.CollectionProfileImage ? `${config.IMG_URL}/collection/${item.CollectionSymbol}/${item.CollectionProfileImage}` : require("../assets/images/add-collection.jpg")} alt="images" />
                                                        </div>
                                                        </Link>
                                                        <div className="inner">
                                                            <Link to ={"/collection/"+item.CollectionSymbol} className="name">{item.CollectionName}</Link>
                                                            <div className="create">created by <Link to={"/profile/"+item.CustomUrl}>{item.DisplayName ? item.DisplayName : address_showing(item.CollectionCreator)}</Link></div>
                                                        </div>
                                                    </div>
                                                    <div className="wish-list">
                                                        <Link to="#" className="heart-icon"></Link>
                                                    </div>
                                                </div>
                                                
                                                    <div className="thumb-collection">
                                                       {item?.Tokens[0]&&
                                                        <div className="left-thumb">
                                                            <Link to={item?.Tokens[0]?.Link}>                                                                                                                        
                                                           <ImgAudVideo
                                                                classname="images"
                                                                file={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Compressed/NFT/${item?.Tokens[0]?.CompressedFile}`}
                                                                origFile={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Original/NFT/${item?.Tokens[0]?.NFTOrginalImage}`}
                                                                type={
                                                                item?.Tokens[0]?.CompressedFile
                                                                    ? item?.Tokens[0]?.CompressedFile?.includes(".webp")
                                                                    ? "image"
                                                                    : item?.Tokens[0]?.CompressedFile.includes(".webm")
                                                                    ? "video"
                                                                    : "audio"
                                                                    : item?.Tokens[0]?.CompressedFile
                                                                }
                                                           />
                                                           </Link>
                                                        </div>}
                                                        {item?.Tokens[1]&&
                                                        <div className="right-thumb">
                                                            {item?.Tokens[1]
                                                            &&<div className="top-cl">
                                                            <Link to={item?.Tokens[1]?.Link}>
                                                            <ImgAudVideo
                                                                classname="images"
                                                                file={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Compressed/NFT/${item?.Tokens[1]?.CompressedFile}`}
                                                                origFile={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Original/NFT/${item?.Tokens[1]?.NFTOrginalImage}`}
                                                                type={
                                                                item?.Tokens[1]?.CompressedFile
                                                                    ? item?.Tokens[1]?.CompressedFile?.includes(".webp")
                                                                    ? "image"
                                                                    : item?.Tokens[1]?.CompressedFile.includes(".webm")
                                                                    ? "video"
                                                                    : "audio"
                                                                    : item?.Tokens[1]?.CompressedFile
                                                                }
                                                           />
                                                           </Link>
                                                            </div>}
                                                            {item?.Tokens[2]
                                                            &&<div className="bottom-cl">
                                                            <Link to={item?.Tokens[2]?.Link}>
                                                            <ImgAudVideo
                                                                classname="images"
                                                                file={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Compressed/NFT/${item?.Tokens[2]?.CompressedFile}`}
                                                                origFile={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Original/NFT/${item?.Tokens[2]?.NFTOrginalImage}`}
                                                                type={
                                                                item?.Tokens[2]?.CompressedFile
                                                                    ? item?.Tokens[2]?.CompressedFile?.includes(".webp")
                                                                    ? "image"
                                                                    : item?.Tokens[2]?.CompressedFile.includes(".webm")
                                                                    ? "video"
                                                                    : "audio"
                                                                    : item?.Tokens[2]?.CompressedFile
                                                                }
                                                           />
                                                           </Link>
                                                            </div>}
                                                        </div>
                                                        }
                                                    </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    </>
                                    )})}
                                </OwlCarousel>   
                                </div>
                            </div>
                        </div>
                        :    <NoData/>
                    }
                     {/* {CreateCollectionState?.slice(4,8)?.length!=0&&
                        <div className="col-md-12">
                        <div className="swiper-container collection-over">
                                <div className="swiper-wrapper">
                                <OwlCarousel className='owl-theme' {...slidetwo} >
                                {CreateCollectionState?.slice(4,8)?.map((item,index) => {
                                        return ( 
                                    <>
                                    {item?.CollectionCount >= 3 &&
                                    <div className="swiper-slide">                               
                                        <div className="slider-item">
                                            <div className="sc-product style1 collection collection2" >
                                                <div className="top">
                                                    <div className="content">
                                                        <Link to ={"/collection/"+item.CollectionSymbol}>
                                                        <div className="author-cl">
                                                            <img src={item.CollectionProfileImage ? `${config.IMG_URL}/collection/${item.CollectionSymbol}/${item.CollectionProfileImage}` : require("../assets/images/add-collection.jpg")} alt="images" />
                                                        </div>
                                                        </Link>
                                                        <div className="inner">
                                                            <Link to ={"/collection/"+item.CollectionSymbol} className="name">{item.CollectionName}</Link>
                                                            <div className="create">created by <Link to={"/profile/"+item.CustomUrl}>{item.DisplayName ? item.DisplayName : address_showing(item.CollectionCreator)}</Link></div>
                                                        </div>
                                                    </div>
                                                    <div className="wish-list">
                                                        <Link to="#" className="heart-icon"></Link>
                                                    </div>
                                                </div>

                                                    <div className="thumb-collection">
                                                       {item?.Tokens[0]&&
                                                        <div className="left-thumb">
                                                            <Link to={item?.Tokens[0]?.Link}>
                                                           <ImgAudVideo
                                                                classname="images"
                                                                file={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Compressed/NFT/${item?.Tokens[0]?.CompressedFile}`}
                                                                origFile={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Original/NFT/${item?.Tokens[0]?.NFTOrginalImage}`}
                                                                type={
                                                                item?.Tokens[0]?.CompressedFile
                                                                    ? item?.Tokens[0]?.CompressedFile?.includes(".webp")
                                                                    ? "image"
                                                                    : item?.Tokens[0]?.CompressedFile.includes(".webm")
                                                                    ? "video"
                                                                    : "audio"
                                                                    : item?.Tokens[0]?.CompressedFile
                                                                }
                                                           />
                                                           </Link>
                                                        </div>}
                                                        {item?.Tokens[1]&&
                                                        <div className="right-thumb">
                                                            {item?.Tokens[1]
                                                            &&<div className="top-cl">
                                                            <Link to={item?.Tokens[1]?.Link}>
                                                            <ImgAudVideo
                                                                classname="images"
                                                                file={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Compressed/NFT/${item?.Tokens[1]?.CompressedFile}`}
                                                                origFile={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Original/NFT/${item?.Tokens[1]?.NFTOrginalImage}`}
                                                                type={
                                                                item?.Tokens[1]?.CompressedFile
                                                                    ? item?.Tokens[1]?.CompressedFile?.includes(".webp")
                                                                    ? "image"
                                                                    : item?.Tokens[1]?.CompressedFile.includes(".webm")
                                                                    ? "video"
                                                                    : "audio"
                                                                    : item?.Tokens[1]?.CompressedFile
                                                                }
                                                           />
                                                           </Link>
                                                            </div>}
                                                            {item?.Tokens[2]
                                                            &&<div className="bottom-cl">
                                                            <Link to={item?.Tokens[2]?.Link}>
                                                            <ImgAudVideo
                                                                classname="images"
                                                                file={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Compressed/NFT/${item?.Tokens[2]?.CompressedFile}`}
                                                                origFile={`${config.IMG_URL}/nft/${item?.CollectionCreator}/Original/NFT/${item?.Tokens[2]?.NFTOrginalImage}`}
                                                                type={
                                                                item?.Tokens[2]?.CompressedFile
                                                                    ? item?.Tokens[2]?.CompressedFile?.includes(".webp")
                                                                    ? "image"
                                                                    : item?.Tokens[2]?.CompressedFile.includes(".webm")
                                                                    ? "video"
                                                                    : "audio"
                                                                    : item?.Tokens[2]?.CompressedFile
                                                                }
                                                           />
                                                           </Link>
                                                            </div>}
                                                        </div>
                                                        }
                                                    </div>
                                            </div>
                                        </div>
                                    </div>}
                                    </>
                                    )})}
                                </OwlCarousel>   
                                </div>
                            </div>
                        </div>
                    }// */}
                    {/* {CreateCollectionState[CreateCollectionState.tab]?.length > 0 && 
                    <div className="col-md-12">
                        <div className="btn-loadmore wow fadeInUp" data-aos="fade-up">
                            <NavLink to="/collectionlist" className="tf-button style-8 loadmore">Explore More <i className="far fa-long-arrow-right"></i></NavLink>
                        </div>
                    </div>} */}
                    {/* </div> */}
                    {/* </div> */}
                    {/* // </section> */}
            {/* // </section>  */}
        {/* */} 

        <section className="tf-section tf-banner-create" >
                <div className="tf-container">
                    <div className="row aligns_row row_2_order">
                        <div className="col-lg-6 text-center">
                        <img src={`${config.IMG_URL}/cmsimg/${homecontentmidd?.img}`} className="create_banner_img" alt="images" classNameName="img-fluid" />
                        </div>
                        <div className="col-lg-6 rightsec">
                        <div className="tf-heading style-3 mb26 wow fadeInUp" >
                        <h2><div className="heading"  dangerouslySetInnerHTML={{__html:homecontentmidd?.description}}></div> </h2>
                        <p><div className="sub_heading"  dangerouslySetInnerHTML={{__html:homecontentmidd?.answer}}></div> </p>
                            {/* <h2 className="heading">Create and Sell NFT With NFT market</h2>
                            <p className="sub_heading">Discover, analyze, create and sell NFTs Faster and smarter than any one else, aided by wide range of collections </p> */}
                            </div>
                            <div className="group-btn wow fadeInUp mb-5" >
                                    <NavLink to="/create" className="tf-button style-2 buy_btn btn-1">Buy item<span><img src={purchase}/>    </span></NavLink>
                                  
                                </div>
                            </div>

          </div>
          </div>
          </section>
            <section className="tf-section tf-hot-pick tf-filter mt-5">
                <div className="tf-container">
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="tf-heading style-3 mb26 wow fadeInUp"  data-aos="fade-up">
                                <h3 className="heading">Hot Sales</h3>
                                {/* <p className="sub-heading">The most creative creator - Based on the last 30 days </p> */}
                            </div>
                        </div>
                        <div className="col-md-12">
                        <div className="top-menu wow fadeInUp"  data-aos="fade-up">
                        <ul className="filter-menu">
                                 <li className={SaleData.tab == "All" ? "active" : ""} onClick={() => HotSales('All')}><a>All</a></li>
                                    {Categorys.map(item=>  <li className={SaleData.tab == item.label ? "active" : ""} onClick={() => HotSales(item.label)}><a>{item.label}</a></li>)}
                                    </ul>
                                <div id="item_category2" className="dropdown">
                                    <NavLink  className="btn-selector nolink ">{filterview.sale}</NavLink>
                                    <ul className="show">
                                        <li onClick={() => {SaleFilter('BLTH',"Price Low to High")}}><span>Price Low to High</span></li>
                                        <li onClick={() => {SaleFilter('BHTL',"Price High to Low")}}><span>Price High to Low</span></li>
                                        <li onClick={() => {SaleFilter('OLD',"Oldest")}}><span>Oldest</span></li>
                                        <li onClick={() => {SaleFilter('LatestDrops',"Recently Created")}}><span>Recently Created</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tf-filter-container row wow fadeInUp"  data-aos="fade-up">
                    {hotsalestate== true ?  <>
    <div className="text-centre">
        <h3  className="load_text">Loading...</h3>
        {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
        {/* <p>Nothing for ReSale at this time</p> */}
                <div className="load-more">
                {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                </div>
        </div>
    </>: SaleData[SaleData.tab]?.length>0 ? SaleData[SaleData.tab]?.slice(8,12).map((item,index)=>
                    <div
                    className="col-xl-3 col-lg-4 col-md-6  tf-loadmore 3d cyber"
                    data-aos="zoom-in-up"
                  >
                    <Card  
                                                            product = {item} 
                                                            type="marketplace"
                                                            index={index}
                                                            LikeList={LikeList}
                                                            LikedTokenList={LikedTokenList}
                                                            setLikedTokenList={setLikedTokenList}
                                                            LikeForwardRef={LikeForwardRef}
                                                        />
                                                        </div>
                                                        )
                        :<NoData/>
                                                    }
                    </div>
                    {SaleData[SaleData.tab]?.length>0 && <div className="col-md-12">
                        <div className="btn-loadmore wow fadeInUp" data-aos="fade-up">
                            <NavLink to="/explore" className="tf-button style-8 loadmore"><img src={loadmore} width={50} height={50}/></NavLink>
                        </div>
                    </div>}
                </div>
            </section>
        <Footer /> 
        </div>   
        </>
    )
}