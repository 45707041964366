import { useSelector } from 'react-redux';
import ERC721 from '../Abi/erc721.json'
import ERC1155 from '../Abi/erc1155.json'
import DETH from '../Abi/erc20.json'
import Market from '../Abi/market.json'
import config from '../views/config/config'
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import Web3 from 'web3';
import Web3Utils from 'web3-utils'

var web3s = new Web3(config.RPC_URL)
console.log("web3s@123", web3s);


export default function useContractProviderHook() {
    const { accountAddress, web3, web3p, coinBalance } = useSelector(state => state.LoginReducer.AccountDetails);
    const { sellerFees, buyerFees } = useSelector(state => state.LoginReducer.ServiceFees);


    const Contract_Base_Validation = () => {
        if (!web3) return 'Connect Your Wallet'
        if (!accountAddress) return 'Connect Your Wallet'
        if (!coinBalance) return "You Don't have Enough Balance"
        else return ''
    }

    const contrat_connection = async (...data) => {
        const CHAIN_ID = config.CHAIN_ID;


        // const provider = await EthereumProvider.init({
        //     projectId: 'b8a1daa2dd22335a2fe1d2e139980ae0', // required
        //     chains: [CHAIN_ID], // required
        //     showQrModal: true // requires @walletconnect/modal
        //   })
        //   await provider.enable()

        // // var providerr=await web3.providers
        // // console.log("Providerrrrr",providerr);
        // // providerr.enable()
        // var web33 = new Web3(provider)
        // console.log('ddddaaattaaaa',...data,web33,accountAddress,web3p);
        if (web3) {
            var contract_value = await new web3.eth.Contract(
                ...data
            );
            return contract_value;
        }
    }

    const GetApproveStatus = async (data, Addr) => {
        try {
            var ConnectContract = await contrat_connection(data == 'Single' ? ERC721 : ERC1155, Addr)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .isApprovedForAll(accountAddress, config.TradeContract)
                    .call()
            return contract_Method_Hash

        }
        catch (e) {
            return 'error'
        }
    }
    const SetApproveStatus = async (data, Addr) => {
        try {
            var ConnectContract = await contrat_connection(data == 'Single' ? ERC721 : ERC1155, Addr)

            // var encoded = await
            // ConnectContract
            //     .methods
            //     .setApprovalForAll(config.TradeContract, true)
            //     .encodeABI();
            //     const tx = {
            //         from: accountAddress,
            //         to: Addr,
            //         data: encoded,
            //       };

            //     var gasLimit = await web3.eth.estimateGas(tx);
            //     tx["gas"] = gasLimit;

            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .setApprovalForAll(config.TradeContract, true)
                    .send({ from: accountAddress }).on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })

            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);

            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            console.log("enna varra", need_data);
            return need_data;

        }
        catch (e) {
            console.log("setapproveerr", e)
            return false
        }
    }
    const get_receipt = async (HashValue) => {
        var receipt = await web3s.eth.getTransactionReceipt(HashValue);
        console.log("sdsadshadsadhfsa", receipt, HashValue)
        if (receipt) {
            return receipt
        }
        else {
            get_receipt(HashValue)
        }
    }
    const minting_721_1155 = async (...data) => {
        console.log("Multipledata", data);
        try {
            const ConnectContract = await contrat_connection(Market, config.TradeContract)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .minting(...data)
                    .send({ from: accountAddress })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })

            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            console.log('reciepppttttt', receipt)
            var TokenCOunts = Web3Utils.hexToNumber(receipt.logs[2].topics[2])
            if (TokenCOunts) {
                var need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                    tokenCounts: TokenCOunts
                }
                return need_data
            }
        }
        catch (e) {
            console.log("Contract Error", e)
            return false
        }


    }
    const approve_721_1155 = async (token_address, ...data) => {

        try {
            const ConnectContract = await contrat_connection(DETH, token_address)

            console.log("sdgjhfsjkghfs",...data,token_address);

            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .approve(...data)
                    .send({ from: accountAddress })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            console.log("hassssh", contract_Method_Hash);
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            console.error("ERRRRR", e)
            return false
        }
    }
    const Token_Balance_Calculation = async (token_Address, data) => {

        try {
            console.log('adddrreeeessss', token_Address, data)
            const ConnectContract = await contrat_connection(DETH, token_Address)
            var bidAMt = await ConnectContract.methods.balanceOf(data).call();
            var tokenbal = Number(web3.utils.fromWei(String(bidAMt)))

            if (Math.abs(tokenbal) < 1.0) {
                var e = parseInt(tokenbal.toString().split('e-')[1]);
                if (e) {
                    tokenbal *= Math.pow(10, e - 1);
                    tokenbal = '0.' + (new Array(e)).join('0') + tokenbal.toString().substring(2);
                }
            } else {
                var e = parseInt(tokenbal.toString().split('+')[1]);
                if (e > 20) {
                    e -= 20;
                    tokenbal /= Math.pow(10, e);
                    tokenbal += (new Array(e + 1)).join('0');
                }
            }
            console.log("WEWEW", tokenbal, typeof (tokenbal))

            return Number(tokenbal).toFixed(10)
        }
        catch (e) {

            return 0
        }
    }
    var buy_bid_price_calculation = (val, decimal) => {
        console.log("vaddl", typeof (String(Number(totfee / 1000000))))
        var toMid = Number(val) * 1000000
        console.log("dfkjhfgjh", typeof (toMid));

        var serfee = (toMid / 100000000) * (web3p.utils.fromWei(String(buyerFees ? buyerFees : 1)) * 1000000)
        var totfee = serfee + toMid
        var tot2cont = web3.utils.toWei(String(Number(totfee / 1000000)).length > 18 ? String(Number(totfee / 1000000).toFixed(18)) : String(Number(totfee / 1000000)))
        var dec = decimal == 18 ? 18 : 18 - (decimal);
        var aprrove = ((tot2cont) / 10 ** dec)
        return (aprrove)
    }
    const cancel_order_721_1155 = async (data) => {
        try {
            var ConnectContract = await contrat_connection(Market, config.TradeContract)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .cancelOrder(data)
                    .send({ from: accountAddress })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data;
        }
        catch (e) {
            return false
        }

    }
    var price_calculation = (data, roy) => {
        try {
            var price = web3.utils.toWei(data);
            // var weii = price*1e6
            // var ser_per = (weii/100000000)*((Wallet_Details.sellerfee/config.decimalvalues)*1000000)
            // var roy_per = (weii/100000000)*((roy)*1000000)
            // var mulWei = ((weii) - (ser_per+roy_per));
            // var getVal = Number(mulWei)/1e6;
            //console..log("you will get lower",price,weii,ser_per,roy_per,getVal);
            var service_from_val = ((price * (sellerFees)) / 1e20)
            var royal_from_val = ((price * (roy * 1e18)) / 1e20)
            var my_val = 0, my_val_royal = 0, getVal = 0;
            if (String(service_from_val).includes('.') && String(service_from_val).split('.').pop().length > 18)
                my_val = service_from_val.toFixed(18)
            else
                my_val = service_from_val

            if (String(royal_from_val).includes('.') && String(royal_from_val).split('.').pop().length > 18)
                my_val_royal = royal_from_val.toFixed(18)
            else
                my_val_royal = royal_from_val
            var whole_val = (((price)) - my_val) / 1e18
            if (String(whole_val).includes('.') && String(whole_val).split('.').pop().length > 18)
                getVal = whole_val.toFixed(18)
            else
                getVal = whole_val
            //console(data, getVal, sellerFees, my_val, my_val_royal)




            if (Math.abs(getVal) < 1.0) {
                var e = parseInt(getVal.toString().split('e-')[1]);
                if (e) {
                    getVal *= Math.pow(10, e - 1);
                    getVal = '0.' + (new Array(e)).join('0') + getVal.toString().substring(2);
                }
            } else {
                var e = parseInt(getVal.toString().split('+')[1]);
                if (e > 20) {
                    e -= 20;
                    getVal /= Math.pow(10, e);
                    getVal += (new Array(e + 1)).join('0');
                }
            }




            console.log('kdjhkgjksdfg', getVal, typeof (getVal))

            return Number(getVal).toFixed(10)

        }
        catch (e) {
            return false
        }
    }
    const place_order_721_1155 = async (...data) => {
        try {
            var ConnectContract = await contrat_connection(Market, config.TradeContract)
            var contract_Method_Hash = await
                ConnectContract.methods
                    .orderPlace(...data)
                    .send({ from: accountAddress })
                    .on('transactionHash', (transactionHash) => {
                        return transactionHash
                    })
            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            console.log("enakenemnfsd", e)
            return false
        }

    }
    const buy_721_1155 = async (Send, CoinName, ...data) => {
        try {
            const ConnectContract = await contrat_connection(Market, config.TradeContract)
            console.log("COINNAME", CoinName,...data)

            if (CoinName === "BNB") {
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .saleToken(...data)
                        .send({
                            from: accountAddress,
                            value: Send
                        })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }
            else {
                console.log("COINNAMEMMMMM", CoinName)
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .saleWithToken(CoinName, ...data)
                        .send({ from: accountAddress })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
            }

            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data
        }
        catch (e) {
            console.error("ERREEER", e)
            return false
        }

    }
    const allowance_721_1155 = async (token_Address, data) => {

        try {
            const ConnectContract = await contrat_connection(DETH, token_Address)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .allowance(data, config.TradeContract)
                    .call()
            return contract_Method_Hash

        }

        catch (e) {
            return false
        }

    }
    const accept_721_1155 = async (...data) => {
        try {
            console.log("accept_721_1155",...data);
            if (web3 != null) {
                const ConnectContract = await contrat_connection(Market, config.TradeContract)
                var contract_Method_Hash = await
                    ConnectContract
                        .methods
                        .acceptBId(...data)
                        .send({ from: accountAddress })
                        .on('transactionHash', (transactionHash) => {
                            return transactionHash
                        })
                const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
                var need_data = {
                    status: receipt.status,
                    HashValue: receipt.transactionHash,
                }
                return need_data
            }
        }
        catch (e) {

            return false
        }

    }

    const GetOwner = async (data, Addr, Tokenaddr) => {
        console.log('functioninputtt', data, Addr, Tokenaddr)
        try {
            var ConnectContract = await contrat_connection(data == 'Single' ? ERC721 : ERC1155, Addr)
            console.log('coooonnnnn', ConnectContract)
            var contract_Method_Hash = await
                ConnectContract
                    .methods
                    .ownerOf(Tokenaddr)
                    .call()
            return contract_Method_Hash

        }
        catch (e) {
            console.log('errrorrrr', e)
            return 'error'
        }
    }

    const GetContractOwner = async (data, Addr) => {
        console.log('functioninputtt', Addr)
        try {
            var ConnectContract = await contrat_connection(data == 'Single' ? ERC721 : ERC1155, Addr)
            console.log('coooonnnnn', ConnectContract)
            var contractowner = await
                ConnectContract
                    .methods
                    .owner()
                    .call()
            return contractowner

        }
        catch (e) {
            console.log('errrorrrr', e, String(e))
            return 'error'
        }
    }

    const Current_NFT_Balance = async (ownerdet, data) => {

        try {
            var currbalance;
            if ((data.ContractType === "721" || data.ContractType === 721)) {
                const ConnectContract = await contrat_connection(ERC721, data.ContractAddress)
                currbalance = await ConnectContract.methods.ownerOf(ownerdet.NFTId).call();
                console.log('ballllaanneceeee1111', currbalance)
                if ((String(currbalance).toLowerCase()) === (String(ownerdet.NFTOwner).toLowerCase())) { return '1'; }
                else {
                    let payload = {
                        NFTId: ownerdet.NFTId,
                        NFTOwner: ownerdet.NFTOwner,
                        NFTBalance: "0",
                    }
                    // var balupd = await NftbalanceUpdate(payload);
                }
                console.log('ballllaanneceeee', currbalance)
            }
            else {
                const ConnectContract = await contrat_connection(ERC1155, data.ContractAddress)
                currbalance = await ConnectContract.methods.balanceOf(ownerdet.NFTOwner, ownerdet.NFTId).call();
                console.log('ballllaanneceeee', currbalance)
                if (currbalance !== null && currbalance !== undefined) {
                    let payload = {
                        NFTId: ownerdet.NFTId,
                        NFTOwner: ownerdet.NFTOwner,
                        NFTBalance: currbalance,
                    }
                    // var balupd = await NftbalanceUpdate(payload);
                    console.log("check other balan 3", payload)


                }
                console.log('ballllaanneceeee', currbalance)
            }
            return String(currbalance);
        }
        catch (e) {
            console.log('errorrrr,e', e)
            return 0
        }
    }

    const Transfer = async (data) => {
        console.log("hellllo", data);

        try {
            const ConnectContract = await contrat_connection(data.ContractType == "1155" ? ERC1155 : ERC721, data.ContractType == "1155" ? config.ERC1155 : config.ERC721)
            console.log("ConnectContract", ConnectContract);
            var contract_Method_Hash
            if (data.ContractType == "1155" || data.ContractType == 1155) {
                contract_Method_Hash = await ConnectContract.methods
                    .safeTransferFrom(
                        data.nftOwner,
                        data.Address,
                        data.NFTId,
                        data.ContractType == "1155" ? data.amount : "0",
                        "0x0"
                    )
                    .send({ from: data.nftOwner })
                    .on("transactionHash", (transactionHash) => {
                        console.log("hasdh", transactionHash);
                        return transactionHash;
                    });
            } else if (data.ContractType == "721" || data.ContractType == 721) {
                contract_Method_Hash = await ConnectContract.methods
                    .safeTransferFrom(
                        data.nftOwner,
                        data.Address,
                        data.NFTId
                    )
                    .send({ from: data.nftOwner })
                    .on("transactionHash", (transactionHash) => {
                        console.log("hasdh", transactionHash);
                        return transactionHash;
                    });
            }



            const receipt = await get_receipt(contract_Method_Hash.transactionHash ? contract_Method_Hash.transactionHash : contract_Method_Hash);
            var need_data = {
                status: receipt.status,
                HashValue: receipt.transactionHash,
            }
            return need_data

        } catch (err) {
            console.log("err in mintin", err);
        }
    };


    return {
        Contract_Base_Validation,
        GetApproveStatus,
        SetApproveStatus,
        minting_721_1155,
        approve_721_1155,
        Token_Balance_Calculation,
        buy_bid_price_calculation,
        cancel_order_721_1155,
        price_calculation,
        place_order_721_1155,
        buy_721_1155,
        allowance_721_1155,
        accept_721_1155,
        GetOwner,
        GetContractOwner,
        Current_NFT_Balance,
        Transfer
    };




}
