import axios from 'axios'
import Config from '../views/config/config'

export const isEmpty = value =>
value === undefined ||
value === null ||
(typeof value === 'object' && Object.keys(value).length === 0) ||
(typeof value === 'string' && value.trim().length === 0) ||
(typeof value === 'string' && value === '0')||
(typeof value === 'number' && value === 0);


// Address cut function like 0x123...345
export const address_showing=(item)=>{
    if(item&&item.toString().length>10){
    var slice_front = item.slice(0,9)
    var slice_end  = item.slice(item.length-9,item.length-1)
    return slice_front+'....'+slice_end
    }
    else return item
}
export const Name_showing=(item)=>{
  if(item&&item.toString().length>10){
  var slice_front = item.slice(0,9)
  // var slice_end  = item.slice(item.length-9,item.length-1)
  return slice_front+'....'
  }
  else return item
}
// Copy Text
export const copydata = (data) =>{
    var copyText = data;
    console.log("COPYDATA",data)
     navigator?.clipboard?.writeText(copyText);
     //toast.success("Copied Successfully")
     return copyText
}

export const NumANdDotOnly = (data) => {
  var data = data.toString()
 var str = data ? data.includes('.') ? data.split('.').length >=3 ? (data.split('.')[0] + '.' + data.split('.')[1]).toString() : data : data : data
   return str.toString().replace(Config.NumDigitOnly,'')
 }

export const NumberOnly = (data) => {
  return data.toString().replace(Config.NumberOnly,'')
}

// Common Formdata function
export const AppenData = (data) =>{
  var formdata = new FormData()
  var SendDta = Object.entries(data).map((item)=>{
      if(Array.isArray(item[1])){
        var come=  item[1].map(data=>{
              formdata.append(item[0],data)
          return formdata
          })
          return come
      
      }
      else{
          formdata.append(item[0],item[1])
          return formdata
      }
  })
return SendDta
}

// Common Axios Function
export const axiosFunc    =   async   (data)  =>  {
  try{
      let Resp    =   await axios(data)
      // console.log('resppppp',Resp,data)
      return Resp
  }
  catch(e){
      return {success:'error',msg:null}
  }
}

export function toFixed(x) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split('e-')[1]);
    if (e) {
        x *= Math.pow(10,e-1);
        x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
        e -= 20;
        x /= Math.pow(10,e);
        x += (new Array(e+1)).join('0');
    }
  }
  return x;
}

